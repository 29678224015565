// @flow
import * as React from 'react';
import ArticleBodyAudio from '../ArticleBodyAudio/ArticleBodyAudio';

import type { ImageDataType, } from '../../flowTypes/ImageDataType';

type PodcastChannelLinks = {
  apple?: String,
  application?: String,
  google?: String,
  spotify?: String,
}

type PodcastChannel = {
  channelName: String,
  channelImage: ImageDataType,
  playerType: 'default' | 'narration',
  site: String,
  links: PodcastChannelLinks,
};

type Props = {
  title: String,
  url: String,
  image: ImageDataType,
  channel: PodcastChannel,
}

export default function PodcastEpisode({ title, url, image, channel, }: Props) {
  const props = {
    title,
    playerType: channel?.playerType || 'default',
    fileUrl: url,
    contentName: title,
    mobileTitle: title,
    channelLinks: channel?.links,
    isOmny: false,
    miscStyles: null,
    isHeadline: false,
    loadAfterFirstClick: true,
    channelLabel: channel?.channelName,
    image,
  };

  return (
    <ArticleBodyAudio {...props} />
  );
}
